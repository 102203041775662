import NextI18Next from 'next-i18next'

const NextI18NextInstance = new NextI18Next({
  defaultLanguage: 'en',
  otherLanguages: ['en', 'ms', 'zh'],
  keySeparator: false,
  nsSeparator: false,
  defaultNS: 'all'
})

export const {
  appWithTranslation,
  withTranslation,
  useTranslation
} = NextI18NextInstance

export default NextI18NextInstance
